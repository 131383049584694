.color-green {
  color: $emerald;
}

.allocation-oms-icon {
  color: #ffa41f;
  font-size: 15px;
}

span.mapping-panel-radio-span {
  margin-right: 0;
  margin-top: 0;
}

.manual-match {
  color: #f35800;
}

.selection-grid {
  border-bottom: 1px solid $black;
  border-left: 1px solid $black;
  text-align: center;
  padding: 5px 0;
  text-transform: uppercase;
  color: $casper;
}

.selection-grid-selected,
.selection-grid:hover {
  background-color: $mirage;
  border-bottom: 2px solid $color-primary;
  padding-bottom: 4px;
}
