@use 'colors';

.color-success {
  color: $color-success;
}

.color-danger {
  color: $color-danger;
}

.color-black {
  color: $black;
}

.color-pastel-blue {
  color: $pastel-blue;
}

.color-shark {
  color: $shark;
}

.dark-bg {
  background-color: $mirage;
}

.dark-lighter-bg {
  background-color: $mirage-light;
}

.dark-accent-bg {
  background-color: $pickled-bluewood;
}

.darkest-bg {
  background-color: $bunker;
}

.border-light-gray {
  border-color: $light-gray;
}
