/* BUTTONS */
$main-green: #35bd64;
$default-grey: #738599;
$default-blue: #168eff;
$primary-blue: #007bff;

.btn {
  font-size: $font-small;
  font-weight: $font-bold;
  padding-left: 1.7rem;
  padding-right: 1.7rem;

  &.btn-default {
    color: $white;
    background: $color-btn-default;

    &:focus,
    &:hover {
      background: darken($color-btn-default, 5%);
    }
  }

  &.btn-mt-top {
    margin-top: 20px;
  }
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: all 0.3s ease-out;
}

.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.7s ease-out;
  transition-property:
    opacity,
    -webkit-transform;
  transition-property: transform, opacity;
  transition-property:
    transform,
    opacity,
    -webkit-transform;
  -webkit-transform: scale(0);
  transform: scale(0);
  pointer-events: none;
}

.waves-effect.waves-light .waves-ripple {
  background-color: rgba(255, 255, 255, 0.45);
}

.waves-effect.waves-red .waves-ripple {
  background-color: rgba(244, 67, 54, 0.7);
}

.waves-effect.waves-yellow .waves-ripple {
  background-color: rgba(255, 235, 59, 0.7);
}

.waves-effect.waves-orange .waves-ripple {
  background-color: rgba(255, 152, 0, 0.7);
}

.waves-effect.waves-purple .waves-ripple {
  background-color: rgba(156, 39, 176, 0.7);
}

.waves-effect.waves-green .waves-ripple {
  background-color: rgba(76, 175, 80, 0.7);
}

.waves-effect.waves-teal .waves-ripple {
  background-color: rgba(0, 150, 136, 0.7);
}

.waves-effect input[type='button'],
.waves-effect input[type='reset'],
.waves-effect input[type='submit'] {
  border: 0;
  font-style: normal;
  font-size: inherit;
  text-transform: inherit;
  background: none;
}

.waves-notransition {
  transition: none !important;
}

.waves-circle {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}

.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.waves-circle {
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}

.waves-block {
  display: block;
}

/* Firefox Bug: link not triggered */
a.waves-effect .waves-ripple {
  z-index: -1;
}

.btn-primary {
  background-color: $primary-blue;
  border-color: darken($primary-blue, 2%);

  &:active,
  &:focus,
  &:hover {
    background-color: darken($primary-blue, 7%);
    border-color: $primary-blue !important;
  }

  &:active:focus,
  &:active:hover {
    background-color: darken($primary-blue, 3%);
    border-color: darken($primary-blue, 5%);
  }
}

.btn-success {
  background-color: $main-green;
  border-color: darken($main-green, 2%);

  &:active,
  &:focus,
  &:hover {
    background-color: lighten($main-green, 5%);
    border-color: $main-green !important;
  }

  &:active:focus,
  &:active:hover {
    background-color: darken($main-green, 3%);
    border-color: darken($main-green, 5%);
  }
}

.btn-blue {
  color: white !important;
  background-color: $default-blue;
  border-color: darken($default-blue, 2%);

  .btn-blue:hover {
    color: white;
  }

  &:active:not([disabled]),
  &:focus:not([disabled]),
  &:hover:not([disabled]) {
    color: white;
    background-color: lighten($default-blue, 3%);
    border-color: lighten($default-blue, 5%);
  }

  &:active:focus,
  &:active:hover {
    color: white;
    background-color: darken($default-blue, 3%);
    border-color: darken($default-blue, 5%);
  }
}

.btn-reset {
  color: $shark !important;
  background-color: transparent;
  border-color: $ghost;

  &:hover {
    color: darken($shark, 5%);
    background-color: lighten($ghost, 15%);
  }

  &:active:focus,
  &:active:hover {
    color: darken($shark, 5%);
    background-color: lighten($ghost, 15%);
  }
}

.btn-default {
  background-color: $default-grey;
  border-color: darken($default-grey, 2%);

  &:active,
  &:focus,
  &:hover {
    background-color: lighten($default-grey, 5%);
    border-color: $default-grey !important;
  }

  &:active:focus,
  &:active:hover {
    background-color: darken($default-grey, 3%);
    border-color: darken($default-grey, 5%);
  }
}

.btn-lg {
  padding: 10px 40px;
}

.btn-custom-red-design {
  color: #fff;
  background-color: #f74053;
  border-color: #f74053;
}

.btn-full {
  width: 100%;
  text-align: center;
}

.button-container {
  border-color: #168eff;
  background-color: #168eff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  cursor: pointer;
  font-weight: 600;
  height: fit-content;
  transition: 0.4s ease;

  span {
    font-size: 14px;
  }

  &.blue {
    color: #ffffff;

    &:hover {
      background-color: #0076e5;
    }
  }

  &.white {
    border: solid 1px #c3c7cd;
    background-color: #fff;
    color: #23282d;
    padding: 6px 17px;

    &:hover {
      background-color: #f7f8fa;
    }
  }

  &.b-r-100 {
    border-radius: 100px;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;
    user-select: none;
  }
}

.btn-action {
  padding: 4px;
  cursor: pointer;
  border-radius: 4px;
  border: transparent;
  background: transparent;

  &:hover {
    background: rgba(0, 0, 0, 0.035);
  }

  i::before {
    margin-right: 4px;
    width: unset;
    height: unset;
  }
}
