.pinnakl-multiselect-container {
  width: 100%;

  .k-list-item,
  .k-list-optionlabel {
    &:hover {
      background-color: $dodger-blue;
    }

    &.k-selected {
      background-color: $cornflower-blue;
    }
  }

  .k-checkbox:checked,
  .k-checkbox.k-checked {
    border-color: $cornflower-blue;
    background-color: $cornflower-blue;
  }

  .k-checkbox:indeterminate,
  .k-checkbox.k-indeterminate {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%231274AC' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M4,8 h8'/%3e%3c/svg%3e");
  }

  .select-all-label {
    background-color: $catswill-white-lighen;
    border-color: $shadow-blue;
  }
}
