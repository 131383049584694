@import 'variables';

.input-label-text {
  color: $blueGray900;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;

  &.disabled {
    color: $blueGray700;
  }

  &.label-required:after {
    content: '*';
    color: $red;
  }
}

.input-hint {
  color: $blueGray700;
  font-size: 12px;
  line-height: 16px;

  &-reserve-height {
    min-height: 16px;
  }
}

.input-validation-error {
  color: $red;
  font-size: 12px;
  line-height: 16px;
}
