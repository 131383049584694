// Theme Specific Variables
$primaryColor: #168eff;
$primaryDarkColor: #0076e5;
$primaryDarkerColor: scale-color($primaryDarkColor, $lightness: -20%);
$highlightBgColor: #e1f1ff;
$primaryTextColor: #ffffff;
$black: #000000;
$white: #ffffff;
$cornflowerBlue: #e3f2fd;
$trout: #495057;
$green: #35bd64;
$middleGray: #c9c9cc;
$athensGray: #e5e6e9;
$red: #ff0000;
$antiFlashWhite: #f0f1f5;
$longColor: #7e5ffc;
$shortColor: #fdd349;
$textBlack: #262a32;
$layoutBlack: #0b1017;
$linkedin-color: #0077b5;

// Figma colors
$blueGray50: #f9fafb;
$blueGray100: #f4f5f7;
$blueGray200: #eaecef;
$blueGray300: #dadde3;
$blueGray400: #c7ccd5;
$blueGray500: #a1a9b9;
$blueGray600: #8b95a8;
$blueGray700: #646f85;
$blueGray800: #373d49;
$blueGray900: #262a32;

$orange50: #fffaf4;
$orange100: #fff4e8;
$orange200: #ffe9d0;
$orange300: #ffd6a7;
$orange400: #ffbf76;
$orange500: #ff8c09;
$orange600: #e37900;
$orange700: #aa5b00;
$orange800: #5c3100;
$orange900: #3f2200;

$green50: #f5fcf7;
$green100: #eaf9ef;
$green200: #d5f3df;
$green300: #b1e9c5;
$green400: #89dea7;
$green500: #36c167;
$green600: #30aa5a;
$green700: #247f44;
$green800: #134525;
$green900: #0d2f19;

$blue50: #f7fbff;
$blue100: #eef7ff;
$blue200: #dceeff;
$blue300: #c1e1ff;
$blue400: #a0d1ff;
$blue500: #59afff;
$blue600: #2a98ff;
$blue700: #006ed5;
$blue800: #003c74;
$blue900: #002950;

$grayScale50: #fafafa;
$grayScale100: #f6f6f6;
$grayScale200: #ececec;
$grayScale300: #dddddd;
$grayScale400: #cbcbcb;
$grayScale500: #aaaaaa;
$grayScale600: #959595;
$grayScale700: #6f6f6f;
$grayScale800: #3c3c3c;
$grayScale900: #292929;

$red50: #fff9f9;
$red100: #fff3f3;
$red200: #ffe7e7;
$red300: #ffd2d2;
$red400: #ffbaba;
$red500: #ff8686;
$red600: #ff5e5e;
$red700: #e10000;
$red800: #7f0000;
$red900: #5b0000;

$yellow500: #ffb200;

$indigo400: #8183f4;

$shade000: #ffffff !default;
$shade50: #f8fafc !default;
$shade100: #f1f5f9 !default;
$shade200: #e2e8f0 !default;
$shade300: #cbd5e1 !default;
$shade400: #94a3b8 !default;
$shade500: #64748b !default;
$shade600: #475569 !default;
$shade700: #334155 !default;
$shade800: #1e293b !default;
$shade900: #0f172a !default;
$shade950: #020617 !default;

$superRadius: 100px;
$mellowMint: #deedbf;
$deepBlue: #d2dcfc;

$colors: (
  'blue': #168eff,
  'green': #35bd64,
  'yellow': #fbc02d,
  'cyan': #00bcd4,
  'pink': #e91e63,
  'indigo': #3f51b5,
  'teal': #009688,
  'orange': $orange500,
  'purple': #783df9,
  'red': #ff4032,
  'bluegray': #607d8b,
  'primary': $primaryColor
);

// Mandatory Designer Variables
@import './variables/general';
@import './variables/form';
@import './variables/button';
@import './variables/panel';
@import './variables/data';
@import './variables/overlay';
@import './variables/message';
@import './variables/menu';
@import './variables/media';
@import './variables/misc';

:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: #{$textBlack};
  --text-color-secondary: #{$blueGray700};
  --layout-black: #{$layoutBlack};
  --primary-color: #2196f3;
  --primary-500: #{$primaryColor};
  --primary-color-text: #ffffff;
  --font-family: 'Source Sans Pro', sans-serif;

  --surface-0: #ffffff;
  --surface-50: #{$grayScale50};
  --surface-100: #{$grayScale100};
  --surface-200: #{$grayScale200};
  --surface-300: #{$grayScale300};
  --surface-400: #{$grayScale400};
  --surface-500: #{$grayScale500};
  --surface-600: #{$grayScale600};
  --surface-700: #{$grayScale700};
  --surface-800: #{$grayScale800};
  --surface-900: #{$grayScale900};

  --red-50: #{$red50};
  --red-100: #{$red100};
  --red-200: #{$red200};
  --red-300: #{$red300};
  --red-400: #{$red400};
  --red-500: #{$red500};
  --red-600: #{$red600};
  --red-700: #{$red700};
  --red-800: #{$red800};
  --red-900: #{$red900};

  --bluegray-50: #{$blueGray50};
  --bluegray-100: #{$blueGray100};
  --bluegray-200: #{$blueGray200};
  --bluegray-300: #{$blueGray300};
  --bluegray-400: #{$blueGray400};
  --bluegray-500: #{$blueGray500};
  --bluegray-600: #{$blueGray600};
  --bluegray-700: #{$blueGray700};
  --bluegray-800: #{$blueGray800};
  --bluegray-900: #{$blueGray900};

  --gray-50: #{$blueGray50};
  --gray-100: #{$blueGray100};
  --gray-200: #{$blueGray200};
  --gray-300: #{$blueGray300};
  --gray-400: #{$blueGray400};
  --gray-500: #{$blueGray500};
  --gray-600: #{$blueGray600};
  --gray-700: #{$blueGray700};
  --gray-800: #{$blueGray800};
  --gray-900: #{$blueGray900};

  --blue-50: #{$blue50};
  --blue-100: #{$blue100};
  --blue-200: #{$blue200};
  --blue-300: #{$blue300};
  --blue-400: #{$blue400};
  --blue-500: #{$blue500};
  --blue-600: #{$blue600};
  --blue-700: #{$blue700};
  --blue-800: #{$blue800};
  --blue-900: #{$blue900};

  --green-50: #{$green50};
  --green-100: #{$green100};
  --green-200: #{$green200};
  --green-300: #{$green300};
  --green-400: #{$green400};
  --green-500: #{$green500};
  --green-600: #{$green600};
  --green-700: #{$green700};
  --green-800: #{$green800};
  --green-900: #{$green900};

  --orange-50: #{$orange50};
  --orange-100: #{$orange100};
  --orange-200: #{$orange200};
  --orange-300: #{$orange300};
  --orange-400: #{$orange400};
  --orange-500: #{$orange500};
  --orange-600: #{$orange600};
  --orange-700: #{$orange700};
  --orange-800: #{$orange800};
  --orange-900: #{$orange900};

  --yellow-500: #{$yellow500};

  --indigo-400: #{$indigo400};

  --content-padding: #{$panelContentPadding};
  --inline-spacing: #{$inlineSpacing};
  --border-radius: #{$borderRadius};
  --super-radius: #{$superRadius};
  --surface-ground: #f8f9fa;
  --surface-section: #ffffff;
  --surface-card: #ffffff;
  --surface-overlay: #ffffff;
  --surface-border: #dee2e6;
  --surface-hover: #e9ecef;
  --maskbg: #{$maskBg};
  --highlight-bg: #{$highlightBg};
  --highlight-text-color: #{$highlightTextColor};
  --focus-ring: #{$focusShadow};
  --input-border-color: #{$middleGray};
  --error-color: #{$red};
  color-scheme: light;
}
