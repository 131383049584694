/* RESET STYLES */

* {
  &:hover,
  &:focus,
  &:active {
    outline: none !important;
  }
}

a:hover,
a:focus,
a:visited {
  text-decoration: none;
  outline: none;
  outline-offset: 0;
}

ol,
ul {
  margin-bottom: 20px;
  padding-left: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.mobile-mb {
  @include media-breakpoint-down(sm) {
    margin-bottom: 20px;
  }
}

// .push-md-down {
// 	@include respond-to(md) {
//   		margin-bottom: 15px;
// 	}
// }
// .push-sm-down {
// 	@include respond-to(sm) {
//   		margin-bottom: 15px;
// 	}
// }
// .push-down {
// 	@include respond-to(xs) {
//   		margin-bottom: 15px;
// 	}
// }
// .mobile-text-center {
// 	@include respond-to(xs) {
//   		text-align: center;
// 	}
// }
// .mobile-text-left {
// 	@include respond-to(xs) {
//   		text-align: left;
// 	}
// }
.relative {
  position: relative;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.full-width {
  width: 100%;
  display: block;
}

.overflow {
  overflow: hidden;
}

.row-without-padding {
  margin-left: 0;
  margin-right: 0;

  > [class*='col-'] {
    padding: 0;
  }
}

.full-height {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.holder {
  margin-bottom: 20px;
}

.negative-margin {
  margin-left: -17px;
  margin-right: -17px;
}
