@import '_components/colors';

.customRedCircleIcon {
  width: 21px !important;
  height: 21px !important;
}

.MB12 {
  margin-bottom: 12px;
}

.MT30 {
  margin-top: 30px;
}

.FB {
  font-weight: 600;
}

.portfolio-rebalancing-grid {
  width: 100%;
  height: 500px;
}

.portfolio-rebalance-grid {
  width: 100%;
  height: 200px;
}

.input-label {
  color: $rolling-stone;
  font-size: 12px;
  font-weight: 600;
}

.ghost-border-bottom {
  border-bottom-color: $ghost !important;
}

.selectPresetDropdown {
  display: flex;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  color: black;
  background-color: $porcelain;

  &:hover {
    color: black;
    background-color: $ghost;
  }
}

.k-list .k-item.k-state-focused {
  box-shadow: none !important;
}
