@import 'variables';

/* NAV */

.navbar {
  background: $nav-bg;
  height: $nav-height;
  padding-top: 0;
  padding-bottom: 0;

  .dropdown-menu {
    @include media-breakpoint-down(xs) {
      position: relative;
      width: 100%;
      margin: 0;
      left: 0;
      right: 0;
    }
  }
}

.navbar-brand {
  display: block;
  width: 160px;
  @include media-breakpoint-down(xs) {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: 100%;
  }
}

.navbar-inner-holder {
  float: left;

  &::after {
    display: block;
    clear: both;
    content: '';
  }

  @include media-breakpoint-down(xs) {
    float: none;
  }
}

.navbar-right {
  float: right;
  @include media-breakpoint-down(xs) {
    float: none;
  }
}

.pnkl-navbar-nav {
  > li {
    float: left;
    position: relative;
    @include media-breakpoint-down(xs) {
      float: none;
    }

    &.navbar-profile {
      > a {
        padding: 11px 10px;

        .profile-pic {
          vertical-align: middle;
          width: 35px;
          height: 35px;
          margin-left: 5px;
        }
      }
    }

    > a,
    a:not([href]):not([tabindex]) {
      display: block;
      color: $nav-color;
      padding: 13px 10px;
      font-size: $font-regular;
      font-weight: $font-semi-bold;
      @include media-breakpoint-down(xs) {
        padding: 10px 0px;
      }

      &:hover {
        color: $white;
      }
    }
  }
}

.tab-container {
  flex: 1;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  color: #222 !important;
  text-transform: capitalize;
  padding: 10px 12px;
  margin-left: 6px;
  border-radius: 8px;
  line-height: 10px;
  height: 33px;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), rgb(239, 242, 245);
  }

  &.tab-selected,
  &.active {
    background-color: #168eff;
    color: #ffffff !important;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #168eff;
    }
  }
}
