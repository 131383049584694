/* SECTION */

.section-padding {
  padding: 15px 5px;
}

.section-white {
  background: $white;
}

.section-full-height {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}
