* {
  box-sizing: border-box;
}

.p-component {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: $fontSize;
  font-weight: $fontWeight;
}

.p-component-overlay {
  background-color: $maskBg;
  transition-duration: $transitionDuration;
}

.p-disabled,
.p-component:disabled {
  opacity: $disabledOpacity;
}

.p-error {
  color: $errorColor;
}

.pi {
  font-size: $primeIconFontSize;
}

.p-icon {
  width: $primeIconFontSize;
  height: $primeIconFontSize;
}

.p-link {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: $fontSize;
  border-radius: $borderRadius;

  &:focus-visible {
    @include focused();
  }
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

.p-component-overlay {
  @keyframes p-component-overlay-enter-animation {
    from {
      background-color: transparent;
    }
    to {
      background-color: var(--maskbg);
    }
  }

  @keyframes p-component-overlay-leave-animation {
    from {
      background-color: var(--maskbg);
    }
    to {
      background-color: transparent;
    }
  }
}
