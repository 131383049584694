@import 'primeicons/primeicons.css';

/* STYLES */
@import './_components/colors';
@import './_components/mixins';

@include scrollBar($submarine);

.search-input {
  background: transparent url('/assets/images/search-icon-light.png') no-repeat scroll 0 8px !important;
  padding-left: 22px !important;
  font-size: 14px;
}

// Kendo multiselect global styles overrides
.k-multiselect {
  .k-multiselect-wrap,
  .k-multiselect-wrap:hover {
    &:after {
      content: '\e006';
      font-family: 'WebComponentsIcons';
      font-size: 16px;
      position: absolute;
      top: 4px;
      width: 1em;
      height: 1em;
      right: 5px;
      pointer-events: none;
      user-select: none;
    }

    border: 0;
    background: transparent;
    border-bottom: 1px solid $slate-gray;
    border-radius: 0;

    .k-button {
      background-image: none;
      border-radius: 10px;
      background-color: #e5e6e9;
      min-height: 18px;

      span {
        font-size: 13px;
        color: $shark;
      }
    }
  }
}

.align-items-baseline {
  align-items: baseline;
}

.overflow-y-no-x {
  overflow-x: hidden;
  overflow-y: auto;
}

.overflow-x-no-y {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-no-x-no-y {
  overflow-x: hidden;
  overflow-y: hidden;
}

.overflow-x-y {
  overflow-x: auto;
  overflow-y: auto;
}
