.p-message {
  margin: $messageMargin;
  border-radius: $borderRadius;

  .p-message-wrapper {
    padding: $messagePadding;
  }

  .p-message-close {
    width: $actionIconWidth;
    height: $actionIconHeight;
    border-radius: $actionIconBorderRadius;
    background: transparent;
    transition: $actionIconTransition;

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    &:focus-visible {
      @include focused();
    }
  }

  &.p-message-info {
    background: $infoMessageBg;
    border: $infoMessageBorder;
    border-width: $messageBorderWidth;
    color: $infoMessageTextColor;

    .p-message-icon {
      color: $infoMessageIconColor;
    }

    .p-message-close {
      color: $infoMessageIconColor;
    }
  }

  &.p-message-success {
    background: $successMessageBg;
    border: $successMessageBorder;
    border-width: $messageBorderWidth;
    color: $successMessageTextColor;

    .p-message-icon {
      color: $successMessageIconColor;
    }

    .p-message-close {
      color: $successMessageIconColor;
    }
  }

  &.p-message-warn {
    background: $warningMessageBg;
    border: $warningMessageBorder;
    border-width: $messageBorderWidth;
    color: $warningMessageTextColor;

    .p-message-icon {
      color: $warningMessageIconColor;
    }

    .p-message-close {
      color: $warningMessageIconColor;
    }
  }

  &.p-message-error {
    background: $errorMessageBg;
    border: $errorMessageBorder;
    border-width: $messageBorderWidth;
    color: $errorMessageTextColor;

    .p-message-icon {
      color: $errorMessageIconColor;
    }

    .p-message-close {
      color: $errorMessageIconColor;
    }
  }

  .p-message-text {
    font-size: $messageTextFontSize;
    font-weight: $messageTextFontWeight;
  }

  .p-message-icon {
    font-size: $messageIconFontSize;
    margin-right: $inlineSpacing;
  }

  .p-icon {
    width: $messageIconFontSize;
    height: $messageIconFontSize;
  }

  .p-message-summary {
    font-weight: 700;
  }

  .p-message-detail {
    margin-left: $inlineSpacing;
  }

  @if variable-exists(secondaryMessageBg) {
    &.p-message-secondary {
      background: $secondaryMessageBg;
      border: $secondaryMessageBorder;
      border-width: $messageBorderWidth;
      color: $secondaryMessageTextColor;

      .p-message-icon {
        color: $secondaryMessageIconColor;
      }

      .p-message-close {
        color: $secondaryMessageIconColor;
      }
    }
  }

  @if variable-exists(contrastMessageBg) {
    &.p-message-contrast {
      background: $contrastMessageBg;
      border: $contrastMessageBorder;
      border-width: $messageBorderWidth;
      color: $contrastMessageTextColor;

      .p-message-icon {
        color: $contrastMessageIconColor;
      }

      .p-message-close {
        color: $contrastMessageIconColor;
      }
    }
  }
}
