@import '../../../../../../node_modules/ag-grid-community/styles';
@import 'mixins';
@import 'variables';

@include grid-styles(
  (
    themes: (
      fresh: (
        extend-theme: alpine
      ),
      business: (
        extend-theme: balham
      )
    ),
    alpine-active-color: $dodger-blue,
    balham-active-color: $dodger-blue,
    header-background-color: transparent
  )
);

.vertical-resizer {
  cursor: row-resize;
  height: 4px;
  background-color: transparent;
  position: relative;
  z-index: 10;
  margin-top: -2px;
  opacity: 0.4;

  &:hover {
    background-color: $dodger-blue;
  }
}

// Root
:root {
  --table-row-active-bg-color: #eef7ff; // active row
  --table-row-selected-bg-color: #f7fbff; // checkbox selected
  --table-row-hover-bg-color: #f9fafb;
}

// THEMES

// Fresh
.ag-theme-fresh {
  --ag-font-size: 14px;

  --ag-row-height: 48px;
  --ag-header-height: 48px;

  --ag-cell-horizontal-padding: 16px;

  .ag-root-wrapper {
    border-left: none !important;
    border-right: none !important;
  }

  --ag-header-cell-hover-background-color: rgba(240, 240, 240, 0.7);

  // Tabs
  .ag-tab {
    border: 1px solid transparent;
    padding: 4px 8px;
    margin: 4px;
  }

  .ag-tab-selected {
    background-color: #f1f1f1;
    border-color: #a9a9a9;
  }

  // Context Menu button
  .ag-icon-menu {
    width: 24px;
    height: 24px;
  }

  .ag-header-cell-menu-button {
    border-radius: 8px;
    transition: 0.2s;

    &:hover {
      background: #eee;
    }
  }

  .ag-icon-menu {
    background-image: url('/assets/images/grid/menu.svg');
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color: transparent;
  }

  // Checkboxes
  .ag-checkbox-input-wrapper {
    &:focus-within {
      box-shadow: none;
    }

    &::after {
      color: transparent;
      border-radius: 4px;
      border: solid 1px #c3c7cd;
    }
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: $denim;
    border: solid 1px $denim;
  }
}

// Business
.ag-theme-business {
  --ag-font-family: 'Source Sans Pro', sans-serif;
  --ag-font-size: 14px;
  --ag-cell-horizontal-padding: 0.6em;
  --ag-row-height: 35px;
  --ag-header-foreground-color: #000;
  --ag-secondary-foreground-color: #000;
  --ag-borders: 1px solid;
  --ag-border-color: #aaa;
  --ag-row-border-color: #eee;
  --ag-cell-horizontal-border: 1px solid #eee;
  --ag-borders-critical: 1px solid;
  --ag-header-column-separator-height: 100%;
  --ag-header-cell-hover-background-color: #eee;
}

// Utilities

ag-grid-angular.ag-grid-full-height {
  height: 100%;

  .ag-root-wrapper-body {
    height: initial;
  }
}

ag-grid-angular.ag-grid-fullheight-resize-column-bar {
  .ag-ltr .ag-header-cell-resize {
    right: -5px;
  }

  .ag-header-cell-resize::after {
    top: 0;
    height: 100%;
    width: 1px;
  }
}

.ag-theme-business,
.ag-theme-fresh {
  --ag-row-hover-color: var(--table-row-hover-bg-color);
  --ag-selected-row-background-color: var(--table-row-selected-bg-color);
  --ag-odd-row-background-color: #fff;

  // Drag and drop
  .ag-root-wrapper {
    border: none;
  }

  .ag-root-wrapper-body {
    border: var(--ag-borders) var(--ag-border-color);
  }

  .ag-column-drop-horizontal {
    border-radius: 8px;
    margin: 0 8px 8px;
    height: 40px;
    padding: 12px 16px;
    transition: 0.3s ease;
    border: 1px dashed #dadde3; // $blueGray300
    background: #fff;
  }

  .ag-column-drop-icon {
    background-image: url('/assets/svg/dropzone.svg');
    color: transparent;
  }
}

.column-drag-state.ag-theme-business,
.column-drag-state.ag-theme-fresh {
  .ag-root-wrapper .ag-column-drop-horizontal {
    border: 1px dashed #a0d1ff; // $blue400
    background: #eef7ff;
    transition: 0.3s ease;
  }
}
