$nav-height: 58px;

/* Breakpoints */
$xsmall-screen: 421px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1024px !default;
$larger-screen: 1400px !default;
$xlarge-screen: 1600px !default;
$xxlarge-screen: 1920px !default;

$xsmall-and-down: 'only screen and (max-width : #{$xsmall-screen})' !default; /* 421px and less */
$small-and-down: 'only screen and (max-width : #{$small-screen})' !default; /* 600px and less */
$medium-and-down: 'only screen and (max-width : #{$medium-screen})' !default; /* 992px and less */
$large-and-down: 'only screen and (max-width : #{$large-screen})' !default; /* 1024px and less */
$larger-and-down: 'only screen and (max-width : #{$larger-screen})' !default; /* 1400px and less */
$xlarge-and-down: 'only screen and (max-width : #{$xlarge-screen})' !default; /* 1600px and less */
$xxlarge-and-down: 'only screen and (max-width : #{$xxlarge-screen})' !default; /* 1920px and less */
$xxlarge-and-up: 'only screen and (min-width : #{$xxlarge-screen + 1})' !default; /* 1921px and more */
