/* SLIDE PANEL */
.slide-panel-item {
  text-align: left;
  width: 390px;
  background: $filter-sibebar-bg;
  right: -12px;
  top: 51px;
  padding: 15px 15px 12px;
  position: absolute;
  top: 46px;
  //height: 3000px;
  z-index: 300;
  color: $slate-gray;
  transform: translate3d(100%, 0px, 0px);
  //@include translate(100%, 0);
  @include transition(800ms);
  height: calc(100vh - 90px);
  //flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* align items in Main Axis */
  align-items: stretch;
  /* align items in Cross Axis */
  align-content: stretch;
  /* Extra space in Cross Axis */
  //height: calc(100vh - 200px);
  //overflow: auto;
  // @include media-breakpoint-down(sm) {
  // 	height: calc(100vh - 280px);
  // }
  // @include media-breakpoint-down(xs) {
  // 	width: 310px;
  // }
  // &.active {
  // 	@include translate(0, 0)
  // 	z-index: 100;
  // }
  .btn-close-icon {
    float: right;
    color: $color-danger;
  }

  hr {
    margin-top: 30px;
  }

  p {
    margin-bottom: 10px;
    font-size: $font-small;
  }

  &.trade-request {
    top: -59px;
    width: 680px;
    transform: translate3d(1360px, 0px, 0px);
    height: calc(100vh + 200px);
  }
}
