html,
body {
  font-family: $body-main-font;
  color: $main-text-color;
  -webkit-font-smoothing: antialiased;
  font-size: $body-font-size;
  background: $body-bg;
}

.main-holder {
  min-height: calc(100vh - 58px);
  font-size: 14px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

* {
  &:hover,
  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 20px;
  //line-height: 1.4;
  font-family: $body-main-font;
  font-weight: $font-semi-bold;

  &:last-child {
    margin-bottom: 0;
  }
}

h1 {
  font-size: 40px;
  @include media-breakpoint-down(sm) {
  }
}

h2 {
  font-size: 22px;
  @include media-breakpoint-down(sm) {
  }
}

h3 {
  font-size: 18px;
  @include media-breakpoint-down(sm) {
  }
}

h4 {
  font-size: 16px;
  @include media-breakpoint-down(sm) {
  }
}

h5 {
  font-size: 14px;
  @include media-breakpoint-down(sm) {
  }
}

h6 {
  font-size: 13px;
  @include media-breakpoint-down(sm) {
  }
}

a {
  color: $link-color;
  @include transition(250ms);
  text-decoration: none;

  &:hover {
    color: darken($link-color, 10%);
    text-decoration: none;
  }
}

.underline {
  text-decoration: underline;
}

button.k-input-button {
  @include transition(150ms);
  border: none;
  background: transparent;
}

.btn-outline-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #ffffff;

  &:hover {
    color: lighten(#6c757d, 10%);
    border-color: lighten(#6c757d, 10%);
  }
}

p {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

hr {
  &.hr-black {
    border-color: $bunker;
  }
}

ol,
ul {
  padding-left: 0;
}

* {
  &:hover,
  &:focus {
    outline: none;
  }
}

/*Background classes*/
