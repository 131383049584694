html,
body {
  font-family: 'Source Sans Pro', sans-serif;
  color: #23282d;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  background: #ffffff;
  leading-trim: both;
  text-edge: cap alphabetic;
  margin: 0;
}

/* source-sans-pro-200 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src:
    local('Source Sans Pro ExtraLight'),
    local('SourceSansPro-ExtraLight'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-200.woff2')
      format('woff2');
}

/* source-sans-pro-200italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src:
    local('Source Sans Pro ExtraLight Italic'),
    local('SourceSansPro-ExtraLightItalic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-200italic.woff2')
      format('woff2');
}

/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src:
    local('Source Sans Pro Light'),
    local('SourceSansPro-Light'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-300.woff2')
      format('woff2');
}

/* source-sans-pro-300italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src:
    local('Source Sans Pro Light Italic'),
    local('SourceSansPro-LightItalic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-300italic.woff2')
      format('woff2');
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src:
    local('Source Sans Pro Regular'),
    local('SourceSansPro-Regular'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-regular.woff2')
      format('woff2');
}

/* source-sans-pro-italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src:
    local('Source Sans Pro Italic'),
    local('SourceSansPro-Italic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-italic.woff2')
      format('woff2');
}

/* source-sans-pro-600 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src:
    local('Source Sans Pro SemiBold'),
    local('SourceSansPro-SemiBold'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600.woff2')
      format('woff2');
}

/* source-sans-pro-600italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src:
    local('Source Sans Pro SemiBold Italic'),
    local('SourceSansPro-SemiBoldItalic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600italic.woff2')
      format('woff2');
}

/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src:
    local('Source Sans Pro Bold'),
    local('SourceSansPro-Bold'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-700.woff2')
      format('woff2');
}

/* source-sans-pro-700italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src:
    local('Source Sans Pro Bold Italic'),
    local('SourceSansPro-BoldItalic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-700italic.woff2')
      format('woff2');
}

/* source-sans-pro-900 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src:
    local('Source Sans Pro Black'),
    local('SourceSansPro-Black'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-900.woff2')
      format('woff2');
}

/* source-sans-pro-900italic - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src:
    local('Source Sans Pro Black Italic'),
    local('SourceSansPro-BlackItalic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-900italic.woff2')
      format('woff2');
}

// Inline SVG styles
i > svg {
  width: inherit;
  height: inherit;
}
