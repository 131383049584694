.primary-spinner .p-progress-spinner-circle {
  animation:
    p-progress-spinner-dash 1.5s ease-in-out infinite,
    primary-progress-spinner-color 6s ease-in-out infinite;
}

@keyframes primary-progress-spinner-color {
  100%,
  0% {
    stroke: var(--primary-color);
  }
  40% {
    stroke: var(--primary-color);
  }
  66% {
    stroke: var(--primary-color);
  }
  80%,
  90% {
    stroke: var(--primary-color);
  }
}
