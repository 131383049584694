@import 'mixins';

// TODO: Finish adding share icon in good way like other icons
@font-face {
  font-family: 'fontello';
  src: url('/assets/fonts/font-icons/font/fontello.eot?61591383');
  src:
    url('/assets/fonts/font-icons/font/fontello.eot?61591383#iefix') format('embedded-opentype'),
    url('/assets/fonts/font-icons/font/fontello.woff2?61591383') format('woff2'),
    url('/assets/fonts/font-icons/font/fontello.woff?61591383') format('woff'),
    url('/assets/fonts/font-icons/font/fontello.ttf?61591383') format('truetype'),
    url('/assets/fonts/font-icons/font/fontello.svg?61591383#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* source-sans-pro-200 - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src:
    local('Source Sans Pro ExtraLight'),
    local('SourceSansPro-ExtraLight'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-200.woff2')
      format('woff2');
}

/* source-sans-pro-200italic - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src:
    local('Source Sans Pro ExtraLight Italic'),
    local('SourceSansPro-ExtraLightItalic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-200italic.woff2')
      format('woff2');
}

/* source-sans-pro-300 - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src:
    local('Source Sans Pro Light'),
    local('SourceSansPro-Light'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-300.woff2')
      format('woff2');
}

/* source-sans-pro-300italic - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src:
    local('Source Sans Pro Light Italic'),
    local('SourceSansPro-LightItalic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-300italic.woff2')
      format('woff2');
}

/* source-sans-pro-regular - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src:
    local('Source Sans Pro Regular'),
    local('SourceSansPro-Regular'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-regular.woff2')
      format('woff2');
}

/* source-sans-pro-italic - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src:
    local('Source Sans Pro Italic'),
    local('SourceSansPro-Italic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-italic.woff2')
      format('woff2');
}

/* source-sans-pro-600 - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src:
    local('Source Sans Pro SemiBold'),
    local('SourceSansPro-SemiBold'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600.woff2')
      format('woff2');
}

/* source-sans-pro-600italic - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src:
    local('Source Sans Pro SemiBold Italic'),
    local('SourceSansPro-SemiBoldItalic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-600italic.woff2')
      format('woff2');
}

/* source-sans-pro-700 - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src:
    local('Source Sans Pro Bold'),
    local('SourceSansPro-Bold'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-700.woff2')
      format('woff2');
}

/* source-sans-pro-700italic - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src:
    local('Source Sans Pro Bold Italic'),
    local('SourceSansPro-BoldItalic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-700italic.woff2')
      format('woff2');
}

/* source-sans-pro-900 - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src:
    local('Source Sans Pro Black'),
    local('SourceSansPro-Black'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-900.woff2')
      format('woff2');
}

/* source-sans-pro-900italic - latin */

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src:
    local('Source Sans Pro Black Italic'),
    local('SourceSansPro-BlackItalic'),
    url('/assets/fonts/font/source-sans-pro-v11-latin/source-sans-pro-v11-latin-900italic.woff2')
      format('woff2');
}

/* Calibri */

@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  src:
    local('Calibri'),
    local('calibri'),
    url('/assets/fonts/font/calibri/calibri.ttf') format('ttf');
}

@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 700;
  src:
    local('Calibri'),
    local('calibri'),
    url('/assets/fonts/font/calibri/calibri_bold.ttf') format('ttf');
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */

/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */

/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('/assets/font/fontello.svg?17662899#fontello') format('svg');
  }
}
*/

// Inline SVG styles
i > svg {
  width: inherit;
  height: inherit;
}

[class*='icon-pinnakl']:before,
[class^='icon-pinnakl']:before {
  @include pnkl-icon();
}

[class^='custom-icon-'] {
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.2rem;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 2rem;
  margin-left: 0.2rem;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.custom-icon-trades-requests {
  background-image: url('/assets/fonts/icons/trades-requests.svg');
  background-repeat: no-repeat;
}

.custom-icon-trades-approved {
  background-image: url('/assets/fonts/icons/trades-approved.svg');
  background-repeat: no-repeat;
}

.custom-icon-risk-reviewed {
  background-image: url('/assets/fonts/icons/risk-reviewed.svg');
  background-repeat: no-repeat;
}

.custom-icon-risk-breaches {
  background-image: url('/assets/fonts/icons/risk-breaches.svg');
  background-repeat: no-repeat;
}

.custom-icon-export-excel {
  background-image: url('/assets/fonts/icons/export-xls.svg');
  background-repeat: no-repeat;
}

.custom-icon-export-pdf {
  background-image: url('/assets/fonts/icons/export-pdf.svg');
}

.custom-icon-reset {
  background-image: url('/assets/fonts/icons/reset.svg');
  background-repeat: no-repeat;
}

.custom-icon-optimal {
  background-image: url('/assets/fonts/icons/optimal.svg');
  background-repeat: no-repeat;
}

.custom-icon-add-edit {
  background-image: url('/assets/fonts/icons/add-edit.svg');
  background-repeat: no-repeat;
}

.custom-icon-email {
  background-image: url('/assets/fonts/icons/email.svg');
  background-repeat: no-repeat;
}

.custom-icon-phone {
  background-image: url('/assets/fonts/icons/phone.svg');
  background-repeat: no-repeat;
}

.custom-icon-file-attachment {
  background-image: url('/assets/fonts/icons/file-attachment.svg');
  background-repeat: no-repeat;
}

.icon-file-attachment {
  width: 14px;
  height: 14px;
  display: inline-block;
  background-color: #738599;
  -webkit-mask: url('/assets/fonts/icons/file-attachment.svg') no-repeat 50% 50%;
  mask: url('/assets/fonts/icons/file-attachment.svg') no-repeat 50% 50%;
  // -webkit-mask-size: cover;
  // mask-size: cover;
}

.icon-pin-filter {
  width: 18px;
  height: 17px;
  mask: url('/assets/images/communications/pin-filter.svg') no-repeat 50% 50%;
  -webkit-mask: url('/assets/images/communications/pin-filter.svg') no-repeat 50% 50%;
  background-color: #738599;
}

.custom-icon-arrow-pinnakl {
  background-image: url('/assets/fonts/icons/arrow-pinnakl.svg');
}

.custom-icon-cashflow {
  background-image: url('/assets/fonts/icons/cashflow-2.svg');
}

.custom-icon-stock-loan {
  background-image: url('/assets/fonts/icons/stock-loan.svg');
}

.custom-icon-corporate-action {
  background-image: url('/assets/fonts/icons/corporate-actions.svg');
}

.custom-icon-help {
  background-image: url('/assets/fonts/icons/help-icon.svg');
  height: 1.25rem;
  width: 1.25rem;
  margin-top: 0.375rem;
}

.custom-icon-pinnakl-menu-email-stats {
  background-image: url('/assets/fonts/icons/pinnakl-menu.svg');
}

.custom-icon-check {
  background-image: url('/assets/fonts/icons/icon-check.svg');
}

.custom-icon-mail-bounced {
  background-image: url('/assets/fonts/icons/mail-bounced.svg');
}

.custom-icon-mail-opened {
  background-image: url('/assets/fonts/icons/mail-opened.svg');
}

.custom-icon-mail-not-opened {
  background-image: url('/assets/fonts/icons/mail-not-opened.svg');
}

.custom-icon-top-chart {
  background-image: url('/assets/fonts/icons/top-chart.svg');
  height: 15px;
  width: 15px;
  background-size: 14px 14px;
  margin-top: 1px;
}

.custom-icon-low-chart {
  background-image: url('/assets/fonts/icons/low-chart.svg');
  height: 15px;
  width: 15px;
  background-size: 14px 14px;
  margin-top: 1px;
}

.custom-is-investor-icon {
  background-image: url('/assets/fonts/icons/is-investor-icon.svg');
  height: 20px;
  width: 20px;
  background-size: 20px;
  display: inline-block;
}

.linkedIn-icon {
  color: #0288d1;
  font-weight: 900;
  font-size: 14px;
  margin-right: 13px;
  margin-left: 5px;

  &::before {
    content: 'in';
  }
}

//
// .icon-pinnakl
//
.icon-pinnakl-side-securities-active:before {
  content: '\e800';
}

/* '' */
.icon-pinnakl-trade-request-done:before {
  content: '\e801';
}

/* '' */
.icon-pinnakl-ok:before {
  content: '\e802';
}

/* '' */
.icon-pinnakl-ok-circle:before {
  content: '\e803';
}

/* '' */
.icon-pinnakl-attention-alt:before {
  content: '\e804';
}

/* '' */
.icon-pinnakl-th-large:before {
  content: '\e805';
}

/* '' */
.icon-pinnakl-exchange:before {
  content: '\e806';
}

/* '' */
.icon-pinnakl-fire:before {
  content: '\e807';
}

/* '' */
.icon-pinnakl-trash-empty:before {
  content: '\e808';
}

/* '' */
.icon-pinnakl-user:before {
  content: '\e809';
}

/* '' */
.icon-pinnakl-note:before {
  content: '\e80a';
}

/* '' */
.icon-pinnakl-bell:before {
  content: '\e80b';
}

/* '' */
.icon-pinnakl-plus-circled:before {
  content: '\e80c';
}

/* '' */
.icon-pinnakl-tasks:before {
  content: '\e80d';
}

/* '' */
.icon-pinnakl-play:before {
  content: '\e80e';
}

/* '' */
.icon-pinnakl-check:before {
  content: '\e80f';
}

/* '' */
.icon-pinnakl-check-empty:before {
  content: '\e810';
}

/* '' */
.icon-pinnakl-ok-squared:before {
  content: '\e811';
}

/* '' */
.icon-pinnakl-plus:before {
  content: '\e812';
}

/* '' */
.icon-pinnakl-upload:before {
  content: '\e813';
}

/* '' */
.icon-pinnakl-filter:before {
  content: '\e814';
}

/* '' */
.icon-pinnakl-up-open-mini:before {
  content: '\e815';
}

/* '' */
.icon-pinnakl-right-open-mini:before {
  content: '\e816';
}

/* '' */
.icon-pinnakl-left-open-mini:before {
  content: '\e817';
}

/* '' */
.icon-pinnakl-back-in-time:before {
  content: '\e818';
}

/* '' */
.icon-pinnakl-clock:before {
  content: '\e819';
}

/* '' */
.icon-pinnakl-search:before {
  content: '\e81a';
}

/* '' */
.icon-pinnakl-cancel:before {
  content: '\e81b';
}

/* '' */
.icon-pinnakl-share:before {
  content: '\e81c';
}

/* '' */
.icon-pinnakl-calendar:before {
  content: '\e81d';
}

/* '' */
.icon-pinnakl-down-open:before {
  content: '\e81e';
}

/* '' */
.icon-pinnakl-left-open:before {
  content: '\e81f';
}

/* '' */
.icon-pinnakl-right-open:before {
  content: '\e820';
}

/* '' */
.icon-pinnakl-up-open:before {
  content: '\e821';
}

/* '' */
.icon-pinnakl-down-open-mini:before {
  content: '\e822';
}

/* '' */
.icon-pinnakl-cog:before {
  content: '\e823';
}

/* '' */
.icon-pinnakl-bell-alt:before {
  content: '\e824';
}

/* '' */
.icon-pinnakl-menu:before {
  content: '\e825';
}

/* '' */
.icon-pinnakl-task-history:before {
  content: '\e826';
}

/* '' */
.icon-pinnakl-task-run-active:before {
  content: '\e827';
}

/* '' */
.icon-pinnakl-task-run:before {
  content: '\e828';
}

/* '' */
.icon-pinnakl-field-arrow-panel:before {
  content: '\e829';
}

/* '' */
.icon-pinnakl-edit-security:before {
  content: '\e82a';
}

/* '' */
.icon-pinnakl-task-time:before {
  content: '\e82b';
}

/* '' */
.icon-pinnakl-tasks-search:before {
  content: '\e82c';
}

/* '' */
.icon-pinnakl-top-notifications:before {
  content: '\e82d';
}

/* '' */
.icon-pinnakl-top-user:before {
  content: '\e82e';
}

/* '' */
.icon-pinnakl-trades-approved:before {
  content: '\e82f';
}

/* '' */
.icon-pinnakl-task-date:before {
  content: '\e830';
}

/* '' */
.icon-pinnakl-section-done:before {
  content: '\e831';
}

/* '' */
.icon-pinnakl-security-add-automatically:before {
  content: '\e832';
}

/* '' */
.icon-pinnakl-side-crm:before {
  content: '\e833';
}

/* '' */
.icon-pinnakl-side-pl:before {
  content: '\e834';
}

/* '' */
.icon-pinnakl-side-reports-active:before {
  content: '\e835';
}

/* '' */
.icon-pinnakl-side-reconciliation-active-svg:before {
  content: '\e836';
}

/* '' */
.icon-pinnakl-security-add-manually:before {
  content: '\e837';
}

/* '' */
.icon-pinnakl-side-pricing-active:before {
  content: '\e838';
}

/* '' */
.icon-pinnakl-redemption:before {
  content: '\e839';
}

/* '' */
.icon-pinnakl-telegram:before {
  content: '\e83a';
}

/* '' */
.icon-pinnakl-field-arrow:before {
  content: '\e83b';
}

/* '' */
.icon-pinnakl-edit-securities:before {
  content: '\e83c';
}

/* '' */
.icon-pinnakl-add-col:before {
  content: '\e83d';
}

/* '' */
.icon-pinnakl-trades-requests:before {
  content: '\e83e';
}

/* '' */
.icon-pinnakl-add-security:before {
  content: '\e83f';
}

/* '' */
.icon-pinnakl-add:before {
  content: '\e840';
}

/* '' */
.icon-pinnakl-close:before {
  content: '\e841';
}

/* '' */
.icon-pinnakl-date-panel:before {
  content: '\e842';
}

/* '' */
.icon-pinnakl-search-security:before {
  content: '\e843';
}

/* '' */
.icon-pinnakl-side-crm-active:before {
  content: '\e844';
}

/* '' */
.icon-pinnakl-shadow-nav-active:before {
  content: '\e845';
}

/* '' */
.icon-pinnakl-side-dashboard-active:before {
  content: '\e846';
}

/* '' */
.icon-pinnakl-side-dashboard:before {
  content: '\e847';
}

/* '' */
.icon-pinnakl-side-oms-active:before {
  content: '\e848';
}

/* '' */
.icon-pinnakl-side-oms:before {
  content: '\e849';
}

/* '' */
.icon-pinnakl-side-pl-active:before {
  content: '\e84a';
}

/* '' */
.icon-pinnakl-side-reconciliation-svg:before {
  content: '\e84b';
}

/* '' */
.icon-pinnakl-side-positions-active:before {
  content: '\e84c';
}

/* '' */
.icon-pinnakl-reporting-categ:before {
  content: '\e84d';
}

/* '' */
.icon-pinnakl-remove:before {
  content: '\e84e';
}

/* '' */
.icon-pinnakl-remove-col:before {
  content: '\e84f';
}

/* '' */
.icon-pinnakl-proceeds:before {
  content: '\e850';
}

/* '' */
.icon-pinnakl-user-empty:before {
  content: '\e851';
}

/* '' */
.icon-pinnakl-live-notifications:before {
  content: '\e852';
}

/* '' */
.icon-pinnakl-table-action:before {
  content: '\e853';
}

/* '' */
.icon-pinnakl-message:before {
  content: '\e854';
}

/* '' */
.icon-pinnakl-side-risk-active:before {
  content: '\e855';
}

/* '' */
.icon-pinnakl-risk-breaches:before {
  content: '\e856';
}

/* '' */
.icon-pinnakl-status-ok:before {
  content: '\e857';
}

/* '' */
.icon-pinnakl-side-menu:before {
  content: '\e858';
}

/* '' */
.icon-pinnakl-task-status-error:before {
  content: '\e859';
}

/* '' */
.icon-pinnakl-table-add-doc:before {
  content: '\e85a';
}

/* '' */
.icon-pinnakl-table-delete:before {
  content: '\e85b';
}

/* '' */
.icon-pinnakl-table-edit:before {
  content: '\e85c';
}

/* '' */
.icon-pinnakl-table-history:before {
  content: '\e85d';
}

/* '' */
.icon-pinnakl-task-results-active:before {
  content: '\e85e';
}

/* '' */
.icon-pinnakl-task-results:before {
  content: '\e85f';
}

/* '' */
.icon-pinnakl-task-schedule:before {
  content: '\e860';
}

/* '' */
.icon-pinnakl-risk-reviewed:before {
  content: '\e861';
}

/* '' */
.icon-pinnakl-new-edit:before {
  content: '\e862';
}

/* '' */
.icon-pinnakl-lookup-col:before {
  content: '\e863';
}

/* '' */
.icon-pinnakl-or:before {
  content: '\e864';
}

/* '' */
.icon-pinnakl-open-lock:before {
  content: '\e865';
}

/* '' */
.icon-pinnakl-side-positions:before {
  content: '\e866';
}

/* '' */
.icon-pinnakl-side-pricing:before {
  content: '\e867';
}

/* '' */
.icon-pinnakl-side-reports:before {
  content: '\e868';
}

/* '' */
.icon-pinnakl-side-risk:before {
  content: '\e869';
}

/* '' */
.icon-pinnakl-side-securities:before {
  content: '\e86a';
}

/* '' */
.icon-pinnakl-side-shadow:before {
  content: '\e86b';
}

/* '' */
.icon-pinnakl-support:before {
  content: '\e86c';
}

/* '' */
.icon-pinnakl-dot-3:before {
  content: '\e86d';
}

/* '' */
.icon-pinnakl-task-schedule-active:before {
  content: '\e86f';
}

/* '' */
.icon-pinnakl-task-history-active:before {
  content: '\e870';
}

/* '' */
.icon-pinnakl-mail-icon:before {
  content: '\e871';
}

/* '' */
.icon-pinnakl-phone-icon:before {
  content: '\e872';
}

/* '' */
.icon-pinnakl-top-score-null-flag-icon:before {
  content: '\e873';
}

/* '' */
.icon-pinnakl-account-active-summary-icon:before {
  content: '\e874';
}

/* '' */
.icon-pinnakl-active-contact-null-icon:before {
  content: '\e875';
}

/* '' */
.icon-pinnakl-activity-summary-graph-button:before {
  content: '\e876';
}

/* '' */
.icon-pinnakl-contact-count-icon:before {
  content: '\e877';
}

/* '' */
.icon-pinnakl-contact-distribution-count-icon:before {
  content: '\e878';
}

/* '' */
.icon-pinnakl-investor-count-icon:before {
  content: '\e879';
}

/* '' */
.icon-pinnakl-shield-danger:before {
  content: '\e87a';
}

/* '' */
.icon-pinnakl-globe:before {
  content: '\e87b';
}

/* '' */
.icon-pinnakl-shield-success:before {
  content: '\e87c';
}

/* '' */
.icon-pinnakl-action-dismiss:before {
  content: '\e87d';
}

/* '' */
.icon-pinnakl-action-run:before {
  content: '\e87e';
}

/* '' */
.icon-pinnakl-ai-icon:before {
  content: '\e87f';
}

/* '' */
.icon-pinnakl-arrow-down:before {
  content: '\e880';
}

/* '' */
.icon-pinnakl-arrow-up:before {
  content: '\e881';
}

/* '' */
.icon-pinnakl-pie:before {
  content: '\e882';
}

/* '' */
.icon-pinnakl-flag:before {
  content: '\e883';
}

/* '' */
.icon-pinnakl-trophy:before {
  content: '\e884';
}

/* '' */
.icon-pinnakl-feed:before {
  content: '\e885';
}

/* '' */
.icon-pinnakl-up-dir:before {
  content: '\e886';
}

/* '' */
.icon-pinnakl-down-dir:before {
  content: '\e887';
}

/* '' */
.icon-pinnakl-floppy:before {
  content: '\e888';
}

/* '' */
.icon-pinnakl-mail:before {
  content: '\e889';
}

/* '' */
.icon-pinnakl-users-outline:before {
  content: '\e88b';
}

/* '' */
.icon-pinnakl-attention:before {
  content: '\e88c';
}

/* '' */
.icon-pinnakl-trash:before {
  content: '\e88d';
}

/* '' */
.icon-pinnakl-high:before {
  content: '\e88e';
}

/* '' */
.icon-pinnakl-med:before {
  content: '\e88f';
}

/* '' */
.icon-pinnakl-rebalance:before {
  content: '\e891';
}

/* '' */
.icon-pinnakl-bar:before {
  content: '\e892';
}

/* '' */
.icon-pinnakl-line:before {
  content: '\e893';
}

/* '' */
.icon-pinnakl-graph:before {
  content: '\e89c';
}

/* '' */
.icon-pinnakl-new-user:before {
  content: '\e89d';
}

/* '' */
.icon-pinnakl-copy:before {
  content: '\e89e';
}

/* '' */
.icon-pinnakl-excel:before {
  content: '\e89f';
}

/* '' */
.icon-pinnakl-doc:before {
  content: '\e86e';
}

/* '' */
.icon-pinnakl-pdf:before {
  content: '\e8a2';
}

/* '' */
.icon-pinnakl-mng-org:before {
  content: '\e8a4';
}

/* '' */
.icon-pinnakl-new-edit-security:before {
  content: '\e8a5';
}

/* '' */
.icon-pinnakl-deal-icon:before {
  content: '\e922';
}

/* '' */
.icon-pinnakl-organization-icon:before {
  content: '\e923';
}

/* '' */
.icon-pinnakl-all-icon:before {
  content: '\e924';
}

/* '' */
.icon-pinnakl-open:before {
  content: '\e9e9';
}

/* '' */
.icon-pinnakl-dist:before {
  content: '\ea32';
}

/* '' */
.icon-pinnakl-history:before {
  content: '\ea56';
}

/* '' */
.icon-pinnakl-qr:before {
  content: '\ea60';
}

/* '' */
.icon-pinnakl-mobile:before {
  content: '\ea61';
}

/* '' */
.icon-pinnakl-email:before {
  content: '\ea62';
}

/* '' */
.icon-pinnakl-partial-allocation:before {
  content: '\ea63';
}

/* '' */
.icon-pinnakl-portal:before {
  content: '\ea64';
}

/* '' */
.icon-pinnakl-download:before {
  content: '\ea65';
}

/* '' */
.icon-pinnakl-edit-login-security:before {
  content: '\ea66';
}

/* '' */
.icon-pinnakl-document:before {
  content: '\ea67';
}

/* '' */
.icon-pinnakl-exls:before {
  content: '\ea68';
}

/* '' */
.icon-pinnakl-statements:before {
  content: '\ea69';
}

/* '' */
.icon-pinnakl-docs:before {
  content: '\f0c5';
}

/* '' */
.icon-pinnakl-circle-empty:before {
  content: '\f10c';
}

/* '' */
.icon-pinnakl-nominee:before {
  content: '\e8a3';
}

/* '' */
.icon-pinnakl-time:before {
  content: '\e8ac';
}

/* '' */
.icon-pinnakl-image:before {
  content: '\e8ad';
}

.icon-pinnakl-pin-outline:before {
  content: '\e88a';
}

/* '' */
.icon-pinnakl-pin:before {
  content: '\e890';
}

/* '' */
.icon-pinnakl-dustbin:before {
  content: '\e962';
}

/* '' */
