@import './_components/colors';
@import './_components/variables';
@import './_components/mixins';

.margin0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.allocationForm {
  display: flex;
  padding: 0 !important;
  border-left: none !important;
}

.allocation_accounts {
  color: $regent-gray;
  padding: 13px 7px;
}

.allocation_account {
  padding: 0;
  border-bottom: 1px solid $pigeon-post;
}

.table-border-bottom {
  border-bottom: 2px solid #c3c7cd;
}

.account_row {
  margin: 16px 0;
  font-size: 14px;
  color: #000;

  &.row-border {
    border-bottom: 1px solid #c3c7cd;
    padding-bottom: 16px;
  }

  &.final_row {
    font-weight: 600;
    margin: 16px 0;
  }

  &:first-child {
    border-top-color: transparent;
  }

  .input-check {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 0px;
  }
}

.account_row div {
  text-align: right;
}

.account_text {
  padding: 0 27px;
  @include respond-to(desktops) {
    padding: 0 20px;
  }
}

.color_black {
  color: $limed-spruce;
}

.pbAccounts_column {
  padding-left: 0;
  padding-right: 15px;

  .column_header {
    border: 2px solid #0275d8;
    cursor: pointer;
  }
}

.tradeAllocationQuantity {
  label {
    display: none;
  }

  pnkl-validation {
    display: none;
  }

  input {
    max-height: 17px;
    max-width: 100px;
    margin-right: 0;
    margin-left: auto;
  }
}

.padding0 {
  padding: 0 !important;
  border-top: none !important;
}

.row_header {
  padding: 5px 0 !important;
}

.remaining_label {
  font-size: 14px;
  margin: 15px 0 40px;
}

.account-names-left {
  max-width: 160px;
  min-width: 55px;
  text-align: center;

  @include respond-to(desktops) {
    font-size: 11px;
    span.input-check label {
      font-size: 11px;
    }
  }
}

.action_buttons,
.allocation_action_buttons {
  height: 100%;
  border-bottom: 1px solid $pigeon-post;

  .reset {
    cursor: pointer;
    width: 35px;
    border: 1px solid #e9edf3;
    background-color: $catswill-white-lighen;
  }

  .optimize {
    cursor: pointer;
    width: 35px;
    border: 1px solid #9adfb1;
    background-color: #e7ffef;
  }

  &.allocation_action_buttons {
    border: none;
  }
}

.allocation_header,
.ratios_header {
  padding: 8px 15px 8px 12px !important;

  span {
    height: 29px;
  }
}

.allocation_text,
.ratios_text {
  padding-top: 5px;
}

.margin00 {
  margin: 0 !important;
}

.left-side {
  background-color: #e4eaf2;
  float: left;
  border-left: 1px solid #b8c6db;
  border-right: 1px solid #b8c6db;
  padding: 15px;
  height: 100%;
}

.oi-border-none .select-search-list-item_selection {
  border-color: transparent !important;
}

.text-align-right {
  text-align: right;
}

.broker-list-container {
  max-height: 110px;
  overflow-x: hidden;
  overflow-y: auto;
}

.table-with-config {
  position: relative;
  overflow: hidden;
}
