@import 'variables';

.security-details-modal {
  min-height: 700px;
}

.modal-rounded-content.p-dialog {
  .p-dialog-content {
    border-radius: $borderRadius;
  }
}

.p-dialog .p-dialog-header {
  .p-dialog-header-icon {
    border-radius: 8px;

    &:hover {
      background-color: $blueGray100;
    }
  }

  .p-dialog-title {
    font-size: 24px;
    line-height: 32px;
  }
}

// For ModalMinimizeService from '@pinnakl/shared/util-providers';
// Body class
.enable-dialog-minimize {
  &.minimized {
    .p-dialog-mask {
      transform: translateY(calc(100vh - 64px));
      opacity: 0.2;

      > div[role='dialog'] {
        min-width: 25vw !important;
        width: 25vw !important;
        left: auto !important;
        top: 0 !important;
        max-height: 100% !important;

        &.p-dialog-draggable .p-dialog-header {
          cursor: initial;
        }

        .p-dialog-header {
          height: 64px !important;
          padding: 8px 16px !important;
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .p-dialog-mask {
    transition:
      top 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
      transform 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
      top 0.5s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
      width 0.5s 0.1s cubic-bezier(0.1, 1.2, 0.3, 1),
      min-width 0.5s 0.1s cubic-bezier(0.1, 1.2, 0.3, 1),
      opacity 0.3s;
  }
}
