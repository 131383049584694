@import './colors';

.password-container {
  position: relative;
  height: 48px;

  input,
  input.pnkl-input {
    height: 100%;
    width: 100%;
    padding: 10px 45px 10px 15px;
  }

  .password-visibility-trigger {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    margin-top: -8px;
    right: 20px;
    cursor: pointer;
    fill: #7c7e83;

    &.active {
      fill: $dodger-blue;
    }
  }
}

.password-label {
  font-size: 14px;
  line-height: 16px;
  color: #858791;
  margin-bottom: 6px !important;
}
