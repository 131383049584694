@import '_components/_colors.scss';

.icon {
  display: flex;
  align-items: center;

  i {
    font-size: 16px;
  }

  color: $denim;

  &.EXTERNAL {
    color: #35bd64;
  }

  .icon-pinnakl-document {
    color: #103ed5;
  }
}

.name-container {
  .name {
    font-weight: 600;
    color: $denim;
    font-size: 14px;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.vdr-document-drag {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 10px;
  background: #fcfcfc;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  min-width: 200px;
}

.data-room .k-list-item {
  &.k-selected,
  &.k-selected:hover {
    background-color: $catskill-white;
  }
}
