.email-icon {
  height: 40px;
  width: 40px;
}

.email-count-value {
  font-size: 20px;
  font-weight: 700;
}

.email-stats-sub-heading {
  line-height: 0.3;
}

.communicationRow {
  .name-avatar {
    height: 45px;
    width: 45px;
  }
}

.notification-item {
  .name-avatar {
    height: 45px;
    width: 45px;
  }
}

.list-item-top-scorers {
  .name-avatar {
    height: 40px;
    width: 40px;
    margin: 2px;
    margin-left: 1px;
  }
}

.email-stats-container {
  .pinnkal-donut-chart {
    max-width: 150px;
  }
}
