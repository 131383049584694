/* MAIN HOLDER */

.main-holder {
  background: #f4f5f7;
  @include transition(500ms);
  padding-left: $aside-collapsed;
  @include media-breakpoint-down(xs) {
    padding-left: 0;
  }

  &.aside-opened {
    padding-left: $aside-opened;
    @include media-breakpoint-down(xs) {
      padding-left: 0;
    }
  }
}
