.mdc-surface-padding-24 {
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 24px;
  }
}

.mat-mdc-dialog-surface.mdc-dialog__surface {
  overflow: hidden;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
}
