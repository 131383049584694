.trade_stage_change {
  -webkit-transition: all 1s linear !important;
  -moz-transition: all 1s linear !important;
  -o-transition: all 1s linear !important;
  transition: all 1s linear !important;
}

.folderAddBody {
  padding: 20px;
  background-color: #fff;
  height: 100%;
}

.custom-container {
  padding: 0 !important;
}

.paddingTB0LR20 {
  padding: 0 20px;
}

.aumForm {
  padding: 20px !important;
  border-bottom: 1px solid #e4e9f0;
  margin-bottom: 210px;
}

.btn-custom-blue-design {
  color: #0db0e9;
  border: 1px solid #0db0e9;
}
