@use 'sass:math';

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  @include invalid-input();
}

p-inputmask.p-inputmask-clearable {
  .p-inputtext {
    padding-right: nth($inputPadding, 2) * 2 + $primeIconFontSize;
  }

  .p-inputmask-clear-icon {
    color: $inputIconColor;
    right: nth($inputPadding, 2);
  }
}

.p-inputmask {
  &.p-variant-filled {
    background-color: $inputFilledBg;

    &:enabled:hover {
      background-color: $inputFilledHoverBg;
    }

    &:enabled:focus {
      background-color: $inputFilledFocusBg;
    }
  }
}
