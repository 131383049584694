/* ASIDE */
$aside-bg: #1d2530;
$aside-collapsed: 50px;
$aside-opened: 212px;
$aside-list-color: $casper;
$aside-link-active: $bunker;
.aside-menu-holder {
  background: $bunker;
  position: fixed;
  top: $nav-height;
  width: $aside-collapsed;
  @include transition(500ms);
  color: $slate-gray;
  font-size: $font-regular;
  z-index: 100;
  height: 100%;
  @include media-breakpoint-down(xs) {
    top: $nav-height;
    width: 100%;
    display: none;
  }

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: $nav-height;
    left: $aside-collapsed;
    width: 12px;
    height: 12px;
    z-index: 100;
    @include transition(500ms);
    background: radial-gradient(100% 100% at bottom right, transparent 100%, $bunker);
    @include media-breakpoint-down(xs) {
      left: 0;
    }
  }

  a {
    color: $white;
  }

  &.aside-opened {
    width: $aside-opened;

    &:before {
      left: $aside-opened;
    }

    .aside-menu-list {
      > li {
        > a {
          > span {
            opacity: 1;
          }
        }
      }
    }
  }
}

.aside-menu-list {
  width: $aside-opened;
  padding-left: 0;
  list-style: none;
  @include media-breakpoint-down(xs) {
    width: 100%;
  }

  > li {
    &.active {
      a {
        color: $white;

        &:before {
          background: $emerald;
        }
      }
    }

    > a {
      display: block;
      color: $aside-list-color;
      padding: 10px 15px;
      position: relative;

      &.tooltip {
        &:after {
          display: none;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        height: 100%;
        width: 3px;
        content: '';
        background: transparent;
        display: block;
        left: 0;
      }

      > i {
        margin-right: 5px;
        font-size: 16px;
      }

      > span {
        @include text-overflow();
        opacity: 0;
        @include media-breakpoint-down(xs) {
          opacity: 1;
        }
      }

      &.active,
      &:focus,
      &:hover {
        background: $aside-link-active;
        color: $white;

        &:before {
          background: $emerald;
        }
      }
    }
  }
}

.clear-both {
  clear: both;
}
