$padding-directions: (
  '': 'padding',
  't': 'padding-top',
  'r': 'padding-right',
  'l': 'padding-left',
  'b': 'padding-bottom',
  'x': (
    'padding-left',
    'padding-right'
  ),
  'y': (
    'padding-top',
    'padding-bottom'
  )
);
$margin-directions: (
  '': 'margin',
  't': 'margin-top',
  'r': 'margin-right',
  'l': 'margin-left',
  'b': 'margin-bottom',
  'x': (
    'margin-left',
    'margin-right'
  ),
  'y': (
    'margin-top',
    'margin-bottom'
  )
);

@mixin spacing-classes($name, $directions, $size, $partly-size, $sizeStr, $measureName: 'rem') {
  @each $dir, $prop in $directions {
    .#{$name}#{$dir}-#{$size}_#{$sizeStr}#{$measureName} {
      @each $propName in $prop {
        #{$propName}: ($size + $partly-size) * 1rem !important;
      }
    }
  }
  @each $dir, $prop in $directions {
    .-#{$name}#{$dir}-#{$size}_#{$sizeStr}#{$measureName} {
      @each $propName in $prop {
        #{$propName}: ($size + $partly-size) * -1rem !important;
      }
    }
  }
}
