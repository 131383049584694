@import 'custom-variables';

@mixin subheading($size: 11px, $line-height: 1rem, $letter-spacing: 0.88px) {
  font-size: $size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}

/* Different devices resolutions */
@mixin respond-to($media) {
  @if $media==old-phones {
    @media #{$xsmall-and-down} {
      @content;
    }
  } @else if $media==phones {
    @media #{$small-and-down} {
      @content;
    }
  } @else if $media==tablets-portrait {
    @media #{$medium-and-down} {
      @content;
    }
  } @else if $media==tablets-landscape {
    @media #{$large-and-down} {
      @content;
    }
  } @else if $media==laptops {
    @media #{$larger-and-down} {
      @content;
    }
  } @else if $media==desktops {
    @media #{$xlarge-and-down} {
      @content;
    }
  } @else if $media==desktops-large {
    @media #{$xxlarge-and-down} {
      @content;
    }
  } @else if $media==desktops-xlarge {
    @media #{$xxlarge-and-up} {
      @content;
    }
  }
}

// OLD MIXINS. REMOVE ASAP
@mixin old-toggle-radios($btn-width: 72px, $padding: 0, $line-height: 1.9) {
  .toggle-radio-btn,
  .toggle-buttons {
    display: inline-block;

    & + .toggle-radio-btn {
      margin-left: 10px;
      @include respond-to(desktops) {
        margin-left: 5px;
      }
    }

    label,
    .toggle-button {
      position: relative;
      z-index: 2;
      float: left;
      font-size: 14px;
      line-height: $line-height;
      color: #23282d;
      text-align: center;
      border-radius: 44px;
      cursor: pointer;
      user-select: none;
      background-color: transparent;
      font-weight: 600;
      width: $btn-width;
      height: 30px;
      border: 1px solid #c3c7cd;
      padding: $padding;
      margin-bottom: 0;
      transition: 250ms;

      &:hover {
        color: #168eff;
      }
    }

    input[type='radio'] {
      display: none;

      &:disabled + label {
        opacity: 0.4;
      }

      &:checked + label {
        background-color: #168eff;
        color: #fff;
        border: 1px solid #168eff;
      }
    }

    .toggle-button {
      & + .toggle-button {
        margin-left: 10px;
        @include respond-to(desktops) {
          margin-left: 5px;
        }
      }

      &.selected {
        background-color: #168eff;
        color: #fff;
        border: 1px solid #168eff;
      }
    }
  }
}
