/* Customizations to the designer theme should be defined here */
/* please re-check /libs/shared/ui/styles/classes.scss for duplications */
@import 'variables';

@layer primeng {
  @mixin focused-ring($ring-color) {
    box-shadow:
      0 0 0 2px #ffffff,
      0 0 0 4px $ring-color,
      0 1px 2px 0 rgba(0, 0, 0, 1);
  }

  .p-selectbutton > .p-button,
  .p-togglebutton.p-button {
    transition:
      background-color $transitionDuration,
      border-color $transitionDuration,
      box-shadow $transitionDuration;
  }

  .p-accordion {
    .p-accordion-header {
      .p-accordion-header-link {
        transition:
          background-color $transitionDuration,
          border-color $transitionDuration,
          box-shadow $transitionDuration;
      }
    }
  }

  .p-tabview {
    .p-tabview-nav {
      li {
        .p-tabview-nav-link {
          transition:
            background-color $transitionDuration,
            border-color $transitionDuration,
            box-shadow $transitionDuration;
        }
      }
    }
  }

  .p-tabmenu {
    .p-tabmenu-nav {
      .p-tabmenuitem {
        .p-menuitem-link {
          transition:
            background-color $transitionDuration,
            border-color $transitionDuration,
            box-shadow $transitionDuration;

          &:not(.p-menuitem-link-active) .p-menuitem-text {
            color: $menuitemTextColor;
          }
        }
      }
    }
  }

  .p-carousel {
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
      background-color: $primaryColor;
    }
  }

  .p-galleria {
    .p-galleria-indicators .p-galleria-indicator.p-highlight button {
      background-color: $primaryColor;
    }
  }

  //.p-button {
  //  &:focus {
  //    @include focused-ring(scale-color($buttonBg, $lightness: 50%));
  //  }
  //
  //  &.p-button-secondary:enabled:focus {
  //    @include focused-ring(
  //        scale-color($secondaryButtonBg, $lightness: 50%)
  //    );
  //  }
  //
  //  &.p-button-success:enabled:focus {
  //    @include focused-ring(
  //        scale-color($successButtonBg, $lightness: 50%)
  //    );
  //  }
  //
  //  &.p-button-info:enabled:focus {
  //    @include focused-ring(scale-color($infoButtonBg, $lightness: 50%));
  //  }
  //
  //  &.p-button-warning:enabled:focus {
  //    @include focused-ring(
  //        scale-color($warningButtonBg, $lightness: 50%)
  //    );
  //  }
  //
  //  &.p-button-help:enabled:focus {
  //    @include focused-ring(scale-color($helpButtonBg, $lightness: 50%));
  //  }
  //
  //  &.p-button-danger:enabled:focus {
  //    @include focused-ring(
  //        scale-color($dangerButtonBg, $lightness: 50%)
  //    );
  //  }
  //}
  .p-datatable {
    .p-datatable-tbody {
      > tr {
        &.p-datatable-dragpoint-top > td {
          box-shadow: inset 0 2px 0 0 $primaryColor;
        }

        &.p-datatable-dragpoint-bottom > td {
          box-shadow: inset 0 -2px 0 0 $primaryColor;
        }
      }
    }
  }

  //.p-speeddial-item {
  //  &.p-focus > .p-speeddial-action {
  //    @include focused-ring(scale-color($buttonBg, $lightness: 50%));
  //  }
  //}
  .p-toast-message {
    backdrop-filter: blur(10px);
  }

  .p-inline-message-text {
    font-weight: 500;
  }

  .p-picklist-buttons .p-button,
  .p-orderlist-controls .p-button {
    transition:
      opacity $transitionDuration,
      background-color $transitionDuration,
      color $transitionDuration,
      border-color $transitionDuration,
      box-shadow $transitionDuration;
  }

  .p-steps {
    .p-steps-item {
      &.p-highlight {
        .p-steps-number {
          background: $primaryColor;
          color: $primaryTextColor;
        }
      }
    }
  }

  // CHECKBOX
  @mixin checked-background {
    background: $primaryColor url('../../../assets/icons/svg/checkbox-checked.svg') center no-repeat;
    border-color: $primaryColor;
  }

  @mixin indeterminate-background {
    background: $primaryColor url('../../../assets/icons/svg/checkbox-indeterminate.svg') center
      no-repeat;
    border-color: $primaryColor;
  }

  .p-checkbox {
    .p-checkbox-box {
      border-radius: 4px;

      .p-icon-wrapper {
        display: none;
      }

      &.p-highlight {
        @include checked-background;
      }
    }

    &:not(.p-checkbox-disabled) {
      .p-checkbox-box {
        &:hover {
          border-color: $black;
        }

        &.p-highlight:hover {
          @include checked-background;
        }
      }
    }
  }

  p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
    margin-top: auto;
    transform: translateY(-50%);
  }

  .p-dialog {
    border-radius: $borderRadius * 2;
    overflow: hidden;
  }

  .p-checkbox-label {
    cursor: pointer;
    color: $black;
    margin-left: 8px;
    font-size: 14px;
    line-height: 16px;
  }

  .p-sidebar {
    &.p-sidebar-absolute {
      position: absolute;
    }

    &.p-grid-sidebar-right {
      height: auto;
      border-right: 1px solid #aaa;
      top: 0;
      bottom: 1px;
    }
  }

  .p-sidebar-mask {
    display: none;
  }

  // UiConfirmationService overlay styling
  body.confirmation-overlay-open {
    &:after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      z-index: 999;
      pointer-events: none;
    }
  }

  .p-calendar {
    width: 100%;
  }

  .p-multiselect {
    max-height: 40px;
    height: 40px;
  }

  .p-overlaypanel {
    --overlayArrowShift: 20%;

    &:before,
    &:after {
      left: var(--overlayArrowShift) !important;
    }

    &.p-overlay-no-arrow {
      &:before,
      &:after {
        display: none;
      }
    }

    &.p-overlay-arrow-center {
      &:before,
      &:after {
        transform: translateY(0) translateX(-50%) !important;
        left: calc(50% + 10px) !important;
      }
    }

    &.p-overlay-arrow-right {
      &:before,
      &:after {
        transform: translateY(0) translateX(-50%) !important;
        left: auto !important;
        right: var(--overlayArrowShift) !important;
      }
    }

    &.p-overlay-arrow-left {
      &:before,
      &:after {
        transform: translateY(0) translateX(-50%) !important;
        right: auto !important;
        left: calc(var(--overlayArrowLeft, 0) + 1.5rem) !important;
      }
    }

    // For proper alignment needs a div wrapper with relative position together with trigger and [appendTo]="wrapper"
    &.p-overlaypanel-center {
      transform: translateY(0) translateX(-50%) !important;
      left: 50% !important;
    }

    &.p-overlaypanel-right {
      transform: translateY(0) translateX(0) !important;
      right: 0 !important;
      left: auto !important;
    }
  }

  .p-menu.p-menu-overlay {
    &.no-hover-menu-items {
      .p-menuitem-content {
        &:hover {
          background: none !important;
        }
      }
    }
  }
}

.dialog-bottom-border-radius-8 {
  .p-dialog-content {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.p-tabmenu-nav-content {
  overflow: visible;
}

.bg-color-long {
  background-color: $longColor;
}

.border-color-long {
  border-color: $longColor;
}

.bg-color-short {
  background-color: $shortColor;
}

.border-color-short {
  border-color: $shortColor;
}
