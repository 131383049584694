/* FLEX */

.row-flex {
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  display: flex;
}

.flex-center {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  display: flex;
}

.row-flex-center {
  justify-content: center;
  align-items: center;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
  align-items: flex-end;
}
