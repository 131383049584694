.preload-spinner {
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('/assets/images/spinner.gif');
  background-size: 130px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
