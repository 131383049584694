@import 'variables';

.global-stream-status-overlay {
  .stream-status {
    height: 8px;
    width: 8px;
    border-radius: 50%;
  }

  .p-overlaypanel-content {
    user-select: none;
    background: $white;
    border-radius: 10px;
    padding: 15px 20px;

    .popup-label {
      font-size: 14px;
      margin-bottom: 15px;
      font-weight: 600;
      color: $black;
    }

    .stream {
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      .stream-label {
        font-size: 12px;
        font-weight: 400;
        color: $black;
        width: 65px;
      }

      .stream-status {
        margin-right: 15px;

        &.established {
          background-color: $green;
        }

        &.connecting {
          background-color: $orange500;

          &.reconnect {
            animation: reconnectingAfterEstablish 3s infinite;

            @keyframes reconnectingAfterEstablish {
              0% {
                background-color: $green;
              }
              25% {
                background-color: $orange500;
              }
              50% {
                background-color: $red;
              }
              75% {
                background-color: $orange500;
              }
              100% {
                background-color: $green;
              }
            }
          }
        }

        &.error {
          background-color: $red;
        }
      }
    }
  }
}
